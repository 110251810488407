import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { isPlatformServer } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class TvfDeviceDetectorService extends DeviceDetectorService {

  /* member variables */

  /* constructors */
  constructor(
    @Inject(PLATFORM_ID) platformId: any
  ) {
    super(platformId);
    if (isPlatformServer(platformId)) {
      super.setDeviceInfo((window.navigator.userAgent as string) || '');
    }
  }

  /* methods */
}