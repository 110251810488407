/**
 * @author Johann Kowalski (traal-devel) <devel@traal.ch>
 */

import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { IconsProviderModule } from './shared/icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { de_DE, NZ_I18N } from 'ng-zorro-antd/i18n';
import de_ch from '@angular/common/locales/de-CH';
import { DatePipe, registerLocaleData } from '@angular/common';
import { TvfNgZorroAntdModule } from './shared/ng-zorro.antd.modules';
import { ApiModule, AuthorizeService, Configuration } from 'tvf-rest-client';

import { PortalHeaderComponent } from './layout/portal-header/portal-header.component';
import { AppRoutingModule } from './app-routing.module';
import { TvfAuthService } from './service/tvf-auth.service';
import { XAuthTokenInterceptor } from './shared/interceptor/xautho-token.interceptor';
import { ChannelThumbPipe } from './shared/pipe/channel-thumb.pipe';
import { HlsStreamPipe } from './shared/pipe/hls-stream.pipe';
import { TimeDifferenceWidthPipe } from './shared/pipe/time-difference-width.pipe';
import { AuthGuard } from './service/auth.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FullLayoutComponent } from './layout/full-layout/full-layout.component';
import { EpgTileComponent } from './page/epg/tile/epg-tile.component';
import { EpgTileColComponent } from './page/epg/tile/epg-tile-col/epg-tile-col.component';
import { LoginComponent } from './page/login/login.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { ErrorPageComponent } from './page/error/error.component';
import { EpgGridComponent } from './page/epg/grid/epg-grid.component';
import { ErrorInterceptor } from './shared/interceptor/error.interceptor';
import { LoadImageDirectiveDirective } from './shared/directive/load-image-directive.directive';
import { EpgGridV2Component } from './page/epg/gridv2/epg-gridv2.component';
import { EpgTileRadioComponent } from './page/epg/tile-radio/epg-tile-radio.component';
import { ShoutcastStreamPipe } from './shared/pipe/shoutcast-stream.pipe';
import { TvfDeviceDetectorService } from './service/tvf-device-detector.service';

registerLocaleData(de_ch);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EpgTileComponent,
    EpgTileColComponent,
    EpgGridComponent,
    ShoutcastStreamPipe,
    HlsStreamPipe,
    PortalHeaderComponent,
    TimeDifferenceWidthPipe,
    BasicLayoutComponent,
    FullLayoutComponent,
    ErrorPageComponent,
    LoadImageDirectiveDirective,
    EpgGridV2Component,
    EpgTileRadioComponent,
    ChannelThumbPipe,
  ],
  imports: [
    BrowserModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TvfNgZorroAntdModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ApiModule.forRoot(() => new Configuration(
      {
        accessToken: null,
        basePath: 'https://tvf-dev.tvf-rest001.tvfactory.ch/pa/sdp/v2'
      }
    )),
  ],
  providers: [
    { provide: NZ_I18N, useValue: de_DE },
    { provide: LOCALE_ID, useValue: 'de-ch' },
    AuthorizeService,
    TvfAuthService,
    TvfDeviceDetectorService,
    HlsStreamPipe,
    ShoutcastStreamPipe,
    DatePipe,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: XAuthTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
