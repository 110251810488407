import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizeService, Configuration, XAuthTokenResponse } from 'tvf-rest-client';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs';
import { TvfDeviceDetectorService } from './tvf-device-detector.service';


export interface User {
  name: string;
  token?: string;
}
export interface ErrorMessage {
  text: string;
}
export interface Device {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TvfAuthService {

  /* constants */
  public static readonly CUR_USER_KEY = 'currentUser';
  public static readonly CUR_DEVICE_ID = 'deviceId';

  /* member variables */
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentDeviceSubject: BehaviorSubject<Device>;
  public currentDevice: Observable<Device>;

  defaultTokenCreated = new EventEmitter<string>();
  accessToken: string = null;


  /* constructors */
  constructor(
    private configuration: Configuration,
    private authorizeService: AuthorizeService,
    private detector: TvfDeviceDetectorService
  ) {

    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem(TvfAuthService.CUR_USER_KEY))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentDeviceSubject = new BehaviorSubject<Device>(
      JSON.parse(localStorage.getItem(TvfAuthService.CUR_DEVICE_ID))
    );
    this.currentDevice = this.currentDeviceSubject.asObservable();
    this.initDeviceId();
  }


  /* methods */
  private initDeviceId():void {
    FingerprintJS.load()
      .then((fp) => fp.get())
      .then((result) => {
        const device: Device = {
          id: result.visitorId
        };
        localStorage.setItem(TvfAuthService.CUR_DEVICE_ID, JSON.stringify(device));
        this.currentDeviceSubject.next(device);
      }
    );
  }

  getCurrentUserValue(): User {
    return this.currentUserSubject.value;
  }


  getCurretDeviceValue(): Device {
    return this.currentDeviceSubject.value;
  }

  /**
   * Checks if the user is authenticated.
   *
   * @returns boolean
   */
  isAuthenticated(): boolean {
    return this.currentUserSubject.value != null;
  }

  hasDevice(): boolean {
    return this.currentDeviceSubject.value != null;
  }

  checkAutoLogin(): boolean {
    let blRetvalue = false;
    if (
      localStorage.getItem(TvfAuthService.CUR_USER_KEY) &&
      this.configuration.apiKeys === undefined
    ) {
      const user: User = JSON.parse(localStorage.getItem(TvfAuthService.CUR_USER_KEY));

      this.accessToken = user.token;

      // for the inteceptor
      this.configuration.apiKeys = {
        Authorization: 'Bearer ' + user.token
      };
      this.currentUserSubject.next(user);
      this.defaultTokenCreated.emit(user.token);
      blRetvalue = true;
    }

    return blRetvalue;
  }

  login(
    username: string,
    password: string,
    callback: (err:any) => any
  ): void {
    // :TODO:@DEV Refactor this with a proper error message.
    if (!this.hasDevice()) {
      alert('No device-id. Please try again.');
      return;
    }
    this.authorizeService.authorizeForm(
      'password',
      this.currentDeviceSubject.value.id + "_" + username,
      'cloud:tvfactory-tvf-dev_ottweb_device',
      username,
      password,
      this.detector.device,
      this.detector.os,
      '(Browser Version: ' + this.detector.browser_version + ')',
      '1.0.0',
      'tvf-cl'
    ).subscribe(
      (response: XAuthTokenResponse) => this.handleLoginResponse(username, response),
      callback
    );
  }

  logout(): void {
    localStorage.removeItem(TvfAuthService.CUR_USER_KEY);
    this.currentUserSubject.next(null);
    this.accessToken = null;
  }

  private handleLoginResponse(
    username: string, 
    response: XAuthTokenResponse
  ): void {
    if (response && response.data) {
      const user: User = {
        name: username,
        token: response.data
      };
      this.accessToken = response.data;

      // for the inteceptor
      this.configuration.apiKeys = {
        Authorization: 'Bearer ' + user.token
      };

      localStorage.setItem(TvfAuthService.CUR_USER_KEY, JSON.stringify(user));
      this.currentUserSubject.next(user);

      this.defaultTokenCreated.emit(response.data);
    } else {
      // throw error
      console.log("ERROR:");
      console.log(response);
    }
  }
}
